@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Genos:wght@100&family=Indie+Flower&family=Kanit:wght@100&family=Mynerve&family=Quicksand:wght@300&family=Shadows+Into+Light&display=swap');

/*
font-family: 'Caveat', cursive;
font-family: 'Genos', sans-serif;
font-family: 'Indie Flower', cursive;
font-family: 'Kanit', sans-serif;
font-family: 'Mynerve', cursive;
font-family: 'Quicksand', sans-serif;
font-family: 'Shadows Into Light', cursive;
*/

/*Header*/

/*Basic Resseting */
*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

/*Much exactly must be 10/16 (16 is the default value) = 62.5%*/
html {
  font-size: 62.5%;
}

/*Proyect Font Definitions*/
body {
  /*font-family: "Lato", sans-serif;*/
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  color: #777;
  padding: 3rem;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif !important;
}

.header {
  height: 90vh;
  background-image: linear-gradient(to right bottom, rgba(145, 171, 202, 0.808), rgba(60, 89, 124, 0.801)), url(../src/assets/images/portada.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
  background-color: #fff;
  }

.header__logo-box {
  position: absolute;
  top: 4rem;
  left: 4rem;
  z-index: 2; }

.header__logo {
  height: 10rem; }

.header__text-box {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

.textPos {
    position: absolute;
    top: 192%;
    left: 84%;
    transform: translate(60%, 90%);
    text-align: center; 
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  text-align: left;
  margin-bottom: 6rem; }

.heading-primary--main {
  display: block;
  font-size: 3.3rem;
  font-weight: 400;
  letter-spacing: 2.2rem;
  animation-name: moveInLeft;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  /*
    animation-delay: 3s;
    animation-iteration-count: 3;
    */ }

.heading-primary--sub {
  display: block;
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 1.75rem;
  animation: moveInRight 2s ease-out;
}

/*Animation for primary-main*/
@keyframes moveInLeft {
  /*Start*/
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  /*Finish*/
  100% {
    opacity: 1;
    transform: translate(0); } }

/*Animation for primary-sub*/
@keyframes moveInRight {
  /*Start*/
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  /*Finish*/
  100% {
    opacity: 1;
    transform: translate(0); } }

/*Animation for button*/
@keyframes moveInBottom {
  /*Start*/
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  /*Finish*/
  100% {
    opacity: 1;
    transform: translate(0); } }

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1.6rem;
 }

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3); }

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3); }

.btn--white {
  background-color: #fff;
  color: #777; }

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.6s; }

.btn--white::after {
  background-color: #fff; }

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0; }

.btn--animated {
  animation: moveInBottom 1s ease-out .75s;
  animation-fill-mode: backwards; }


/*Fin Header*/



main {
    font-family: 'Quicksand', sans-serif !important;
    min-height: 90vh;
    background-color: #e0e4fd;
    color: #26402d;
}

.navbar, nav, Nav {
    font-family: 'Quicksand', sans-serif !important;
    font-size: 1.8rem;
}

.colorMenu {
    background-color: #b9d9eb !important;
    color: #26402d !important; 
  }
  
.colorMenu:hover {
    color: #212642 !important;
    letter-spacing: 0.1rem;
}

.footer, .button {
    font-family: 'Quicksand', sans-serif !important;
}

.sizeLogo {
    max-height: 180px;
    width: 200px;
}

/*For HomeScreen.js purpose*/
p {
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
}
h1 {
    font-weight: bold;
    color: #444;
    font-size: 2.81rem;
    margin-bottom: 20px;
}
h2 {
    font-weight: 300;
    color: #444;
    font-size: 53px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}
h3 {
    font-size: 30px;
    color: #444;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}
h4 {
    font-size: 24px;
    color: #444;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
}
h5 {
    font-size: 15px;
    color: #444;
    font-weight: bold;
    text-transform: uppercase;
}
a {
    text-decoration: none;
    color: #444;
}

strong {
    font-weight: bold;
}
small {
    font-size: 13px;
    color: #777;
    font-style: italic;
}
.clear {
    clear: both;
}
.wrapper {
    margin: 0 auto;
    padding: 0 10px;
    width: 1600px;
}

#hero-image {
    padding-top: 1px;
    background: #e8eced url('./assets/images/portada.jpg') no-repeat center;
    border: 2px solid #444;
    margin: 2px;
 }

.masthead {
    min-height: 60vh;
    position: relative;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
 }

 .masthead .container {
    overflow: hidden;
 }

 .masthead img {
    position: absolute;
    bottom: calc(100% - 200px);
    right: calc(100% - 660px);
    width: 180px;

 }

 .color-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.6573004201680672) 0%, rgba(129,129,129,0) 48%);
 }

 /*.textPos {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
 }*/

.loreok {
    position: absolute;
    left: 1650px;
    top: 55px;
}

.sectionrl {
    margin-top: 5rem !important;
}

.button-1 {
    display: block;
    text-align: center;
    background: #e05353;
    border-radius: 3px;
    color: #fff;
    width: 180px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    margin: 0 auto;
    text-decoration: none;
}
.button-1:hover {
    background-color: #421d74;
    color: #fff;
}

#primary-content {
    background-color: #f5f8f3;
    padding: 60px 0;
    text-align: center;
}

#secondary-image1 {
    height: 580px;
    padding-top: 1px;
    background: #e8eced url('./assets/images/yoga5.jpg') no-repeat center;
}

#secondary-image2 {
    height: 580px;
    padding-top: 1px;
    background: #e8eced url('./assets/images/acupuncture.jpg') no-repeat center;
}

#secondary-image3 {
    height: 580px;
    padding-top: 1px;
    background: #e8eced url('./assets/images/mandalas.png') no-repeat center;
}

#secondary-content {
    background-color: #e2e5f8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 30px 0;
    text-align: center;
}
#secondary-content article {
    width: 460px;
    height: 270px;
    float: left;
    background-color: #f5f5f5;
}

.overlay {
    background-color: #fffffff2;
    height: 270px;
    width: 190px;
    padding: 10px;
}
article h4 {
    border-bottom: 1px solid #02b8dd;
    padding-bottom: 20px;
}
.more-link {
    border: 1px solid #02b8dd;
    color: #02b8dd;
    padding: 2px 20px;
    border-radius: 3px;
    text-decoration: none;
}
.more-link:hover {
    background-color: #02b8dd;
    color: #fff;
}

#cta {
    background-color: #e6e8f7;
    padding: 60px 0;
    text-align: center;
}
#cta h3 {
    display: block;
    margin: 0 auto 20px auto;
    width: 400px;
    border-bottom: 1px solid #02b8dd;
    padding: 0 0 20px 0;
}
.button-2 {
    display: block;
    margin: 0 auto;
    border: 2px solid #02b8dd;
    color: #02b8dd;
    border-radius: 3px;
    width: 180px;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-decoration: none;
}
.button-2:hover {
    background-color: #02b8dd;
    color: #fff;
}

.textam {
    font-size: 3.3rem;
}

@media (min-width: 360px ) and (max-width: 576px) {
  .header {
    height: 45vh;
  }

  .heading-primary--main {
    font-size: 1.4rem;
    margin: 2.9rem;
  }

  .heading-primary--sub {
    font-size: 1rem;
    margin: 1.9rem;
  }

  .textPos {
    position: absolute;
    top: 110%;
    left: 35%;
    transform: translate(60%, 90%);
    text-align: center;
    font-size: calc(1rem + .3vw) !important;
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 0.8rem;
 }


 .header__logo-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
}

.header__logo {
  height: 3.5rem;
}

}


@media (min-width: 576px ) and (max-width: 768px) {
  .header {
    height: 50vh;
  }

  .heading-primary--main {
    font-size: 1.6rem;
  }

  .textPos {
    position: absolute;
    top: 132%;
    left: 68%;
    transform: translate(60%, 90%);
    text-align: center; 
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1rem;
 }

 .header__logo-box {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 2;
}

.header__logo {
  height: 3.5rem;
}

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .masthead {
        min-height: 75vh;
    }

    .masthead img {
        position: absolute;
        bottom: 4rem;
        right: 3.5rem;
        width: 90px;
    }

    .textam {
        font-size: 1.5rem !important;
    }

    .logo {
        width: 160px !important;
    }

    .header {
      height: 50vh;
    }

    .heading-primary--main {
      font-size: 1.6rem;
    }

    .textPos {
      position: absolute;
      top: 142%;
      left: 84%;
      transform: translate(60%, 90%);
      text-align: center;
      font-size: 1rem;
  }

  .header__logo-box {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
  }

  .header__logo {
    height: 4.2rem; 
  }

  .btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1rem;
 }
}


@media (min-width: 991.98px) and (max-width: 1400px) { 
    .masthead img {
        position: absolute;
        bottom: calc(100% - 200px);
        right: calc(100% - 800px);
        width: 178px;
    
    }

    .header {
      height: 78vh;
    }
  
    .heading-primary--main {
      font-size: 1.6rem;
    }
  
    .textPos {
      position: absolute;
      top: 192%;
      left: 84%;
      transform: translate(60%, 90%);
      text-align: center; 
  }
  
  .btn:link,
  .btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
   }
  
   .header__logo-box {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
  }
  
  .header__logo {
    height: 7.5rem;
  }

 }


@media (min-width: 1400px) and (max-width:2400px) { 
    .masthead img {
        position: absolute;
        bottom: calc(100% - 200px);
        right: calc(100% - 900px);
        width: 190px;
    
     }

     .header {
      height: 85vh;
    }
  
    .heading-primary--main {
      font-size: 2.2rem;
    }
  
    .textPos {
      position: absolute;
      top: 160%;
      left: 84%;
      transform: translate(60%, 90%);
      text-align: center;
      font-size: 2rem;
  }
  
  .btn:link,
  .btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 10rem;
    transition: all .2s;
    position: relative;
    font-size: 1.5rem;
   }
  
   .header__logo-box {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 2;
  }
  
  .header__logo {
    height: 8.5rem;
  }

 }


.app {
    margin: 1rem 4rem;
  }
  
  .app .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  /* Snow Theme */
  .app .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
  /* Bubble Theme */
  .app .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
  }
  
  .app .ql-editor {
    min-height: 18em;
  }
  
  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }

  .ql-editor  {
    line-height: 1.20 !important;
    font-family: Calibri, Helvetica !important;
    font-size: 16px !important;
    -webkit-margin-after:0px;
    -webkit-margin-before:0px;
    background-color: transparent;
 }
 .ql-editor p 
 {
  line-height: 1.2;
}




